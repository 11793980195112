<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="bg-white">
    <div class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div
        class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8"
      >
        <div>
          <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
            {{ title1 }}
          </h2>
          <div class="mt-3">
            <p class="text-lg text-gray-500 ">
              {{ contact1 }}
            </p>
          </div>
          <div class="mt-9">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: phone -->
                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500 ">
                <p class="hover:text-blue-500">
                  <a :href="`tel:${phone1}`">{{ phone1 }}</a>
                </p>
                <p class="mt-1">
                  {{ time1 }}
                </p>
              </div>
            </div>
            <div class="flex mt-6">
              <div class="flex-shrink-0">
                <!-- Heroicon name: mail -->
                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500 hover:text-blue-500">
                <p>
                  <a :href="`mailto:${email1}`">{{ email1 }} </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 md:mt-0">
          <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
            {{ title2 }}
          </h2>
          <div class="mt-3">
            <p class="text-lg text-gray-500">
              {{ contact2 }}
            </p>
          </div>
          <div class="mt-9">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: phone -->
                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500 ">
                <p class="hover:text-blue-500">
                  <a :href="`tel:${phone2}`">{{ phone2 }}</a>
                </p>
                <p class="mt-1">
                  {{ time2 }}
                </p>
              </div>
            </div>
            <div class="flex mt-6">
              <div class="flex-shrink-0">
                <!-- Heroicon name: mail -->
                <svg
                  class="w-6 h-6 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
              <div class="ml-3 text-base text-gray-500 hover:text-blue-500">
                <p>
                  <a :href="`mailto:${email2}`"> {{ email2 }} </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactSection",
  props: {
    title1: String,
    contact1: String,
    phone1: String,
    time1: String,
    email1: String,
    title2: String,
    contact2: String,
    phone2: String,
    time2: String,
    email2: String,
  },
};
</script>
