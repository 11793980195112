<template>
  <div>
    <AppMenu />

    <ContactSection
      :title1="$t('contact1')"
      :contact1="$t('contact2')"
      :email1="$t('contact5')"
      :phone1="$t('contact3')"
      :time1="$t('contact4')"
      :title2="$t('contact6')"
      :contact2="$t('contact7')"
      :email2="$t('contact10')"
      :phone2="$t('contact8')"
      :time2="$t('contact9')"
    />
    <ContactSection
      :title1="$t('contact11')"
      :contact1="$t('contact12')"
      :email1="$t('contact15')"
      :phone1="$t('contact13')"
      :time1="$t('contact14')"
      :title2="$t('contact11')"
      :contact2="$t('contact12')"
      :email2="$t('contact15')"
      :phone2="$t('contact13')"
      :time2="$t('contact14')"
    />
    <CtaSection />
    <FooterSection />
  </div>
</template>

<script>
import AppMenu from "../components/AppMenu";
import ContactSection from "../components/ContactSection";
import FooterSection from "../components/FooterSection.vue";
import CtaSection from "../components/CtaSection.vue";

export default {
  name: "Contacts",
  components: {
    ContactSection,
    AppMenu,
    FooterSection,
    CtaSection,
  },
};
</script>
